import request from '@/utils/request'

// 获取导航栏的信息
export const GetNav = () => request.get('/navs/navs/')

// 获取首页的数据
export const GetIndex = () => request.get('/indexes/indexes/')

// 获取公司的信息
export const GetCompany = () => request.get('/companies/companies/')
