import { GetEngerSolve } from '@/api/solve'

export default {
  namespaced: true,
  state: {
    energy: {}, // 能源计量解决方案
    intel: {}, // 智能变配电运维解决方案
    wisdom: {}, // 智慧能源管理系统解决方案
    pro: {}// 产品中心
  },
  mutations: {
    setenergy (state, item) {
      state.energy = item
    },
    setintel (state, item) {
      state.intel = item
    },
    setwisdom (state, item) {
      state.wisdom = item
    },
    setpro (state, item) {
      state.pro = item
    }
  },
  actions: {
    // 获取能源计量解决方案
    async getenergy ({ commit }, id) {
      const res = await GetEngerSolve(id)
      // console.log(res)
      commit('setenergy', res.results[0])
    },
    // 获取智能变配电运维解决方案
    async getintel ({ commit }, id) {
      const res = await GetEngerSolve(id)
      // console.log(res)
      commit('setintel', res.results[0])
    },
    // 获取智慧能源管理系统解决方案
    async getwisdom ({ commit }, id) {
      const res = await GetEngerSolve(id)
      // console.log(res)
      commit('setwisdom', res.results[0])
    },
    // 获取产品中心
    async getpro ({ commit }, id) {
      const res = await GetEngerSolve(id)
      // console.log(res)
      commit('setpro', res.results[0])
    }
  }
}
