import { GetAbout } from '@/api/about'
export default {
  namespaced: true,
  state: {
    aboutItem: {} // 关于我们的信息
  },
  mutations: {
    setaboutItem (state, item) {
      state.aboutItem = item
    }
  },
  actions: {
    // 获取关于我们的信息
    async  getabout ({ commit }) {
      const res = await GetAbout()
      commit('setaboutItem', res.results)
    }
  },
  getters: {}
}
