import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import solve from './modules/solve'
import serve from './modules/serve'
import cases from './modules/cases'
import about from './modules/about'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    home,
    solve,
    serve,
    cases,
    about
  }
})

export default store
