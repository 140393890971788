import { GetServe } from '@/api/serve'
export default {
  namespaced: true,
  state: {
    serve: {}, // 服务中心信息
    down: {}// 下载中心信息
  },
  mutations: {
    setserve (state, item) {
      state.serve = item
    },
    setdown (state, item) {
      state.down = item
    }
  },
  actions: {
    // 获取服务中心信息
    async getserve ({ commit }, id) {
      const res = await GetServe(id)
      commit('setserve', res.results[0])
    },
    // 获取下载中心信息
    async getdown ({ commit }, id) {
      const res = await GetServe(id)
      commit('setdown', res.results[0])
    }
  },
  getters: {}
}
