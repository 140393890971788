import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/css/base.css'
import './assets/icon/font_4292195_46pqsjlis29/iconfont.css'
// 引入bootstrap
import 'jquery'
import 'popper.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// 全局注册组件
import HeadNav from './components/HeadNav.vue'
import FootNav from './components/FootNav.vue'

import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false
Vue.component('HeadNav', HeadNav)
Vue.component('FootNav', FootNav)
Vue.use(BaiduMap, {
  ak: 'xRr37vc5LX8DKcAP2c9NqEmksY39QYvX' // 这个地方是官方提供的ak密钥
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
