<template>
  <div class="head  sticky-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-white ">
      <a class="navbar-brand" href="#/index">
        <img :src="$store.state.home.companyItem.logo" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li  class="nav-item active dropdown" v-for="(item,index) in navlist" :key="item.id" >
            <a data-toggle="dropdown" href="javascript:void(0);" :class="[{navcss:checked===index},{'nav-link':true}]" @click="jump(index,item.path)"  >{{item.name}}</a>
            <div class="dropdown-menu" v-show="index === 1 || index === 2">
              <div v-for="(item1,index1) in item.children" :key="index1">
                <a  class="dropdown-item procenter" v-if="item1.name=='产品中心'">{{item1.name}}

                <div v-if="item1.children.length" class="showpro">
                  <a  v-for="(item2,index2) in item1.children" :key="index2" class="more-product" :href="'#'+item2.path+'?id='+item2.id">{{item2.name}}</a>
                </div>
                </a>
                <a v-else class="dropdown-item" :href="'#'+item1.path+'?id='+item1.id">{{item1.name}}</a>

              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checked: 0,
      navlist: [] // 导航信息
    }
  },
  created () {
    // 页面跳转对应头部导航选中状态
    if (this.$route.path === '/energy' || this.$route.path === '/intel' || this.$route.path === '/wisdom' || this.$route.path === '/prodetail' || this.$route.path === '/nrsdetail') {
      this.checked = 1
    }
    if (this.$route.path === '/servecenter' || this.$route.path === '/downcenter') {
      this.checked = 2
    }
    if (this.$route.path === '/case') {
      this.checked = 3
    }
    if (this.$route.path === '/about' || this.$route.path === '/aboutdetail') {
      this.checked = 4
    }
    // 先从vuex 获取数据
    if (this.$store.state.home.navItem.length) {
      this.navlist = this.$store.state.home.navItem
    } else {
      this.getnav()
    }
  },
  methods: {
    // 获取导航栏信息
    async getnav () {
      await this.$store.dispatch('home/getnav')
      this.navlist = this.$store.state.home.navItem
      // console.log(this.navlist)
    },
    jump (index, path) {
      this.checked = index
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
  .head{
    background-color: #ffffff;
    margin: 0 auto;
    box-shadow: 0 0.0625rem 0.1875rem rgba(26,26,26,.1);
    border-bottom: 1px solid #f0f0f0;
    .navbar{
      height: 86px;
      padding: 0;
      img{
        width: 12rem;
        height: 40px;
        margin-left: 15px;
      }
      ul{
        background-color: #fff;
        li{
          margin-left: 30px !important;
          font-size: 20px;
          margin: 0 30px;
            a{
              &:hover{
              color: #33ba00 !important;
              }
          }
        }
      }
      .navbar-toggler{
        margin-right: 15px;
      }
    }
  }
  //点击后 导航样式
  .navcss{
    color: #33ba00 !important;
  }
  //头部
  @media screen and (min-width:1125px){
    .navbar{
      ul{
        margin-left: 100px !important;
      }
    }
  }
  @media screen and (max-width:768px){
    .navbar{
      ul{
        li{
          a{
            font-size: 16px !important;
          }
        }
      }
    }
  }
  .more-product{
    margin-top: 1rem;
    display: block;
    cursor: pointer;
    color: #212529;
    padding-left: 1.5rem;
    text-decoration: none;
    &:first-child{
      margin-top: 0.5rem;
    }
  }
  .showpro{
    display: none;
  }
  .procenter{
    &:hover{
      .showpro{
        display: block;
      }
    }
  }

  @media screen and (max-width:1080px) {
    .showpro{
      display: block;
    }
  }
</style>
