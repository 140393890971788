<template>
  <div>
    <!-- 底部区域 -->
    <div class="text-white bg-dark">
      <div class=" foot-top ">
        <div class="row">
          <div class="col-8 col-lg-2" v-if="companyItem.logo">
            <a href="#/index"><img :src="companyItem.logo" alt=""></a>
          </div>
          <div class="col-4 col-lg-1" v-if="navlist.length">
            <ul class="list-unstyled text-small foot-list">
              <li>
                <a :href="'#' + navlist[4].path">{{navlist[4].name}}</a>
              </li>
            </ul>
          </div>
          <div class="col-8 col-lg-2" v-if="navlist.length">
            <ul class="list-unstyled text-small foot-list">
              <li>
                <a href="javascript:void(0)">{{navlist[1].name}}</a>
              </li>
              <li v-for="item in navlist[1].children " :key="item.id">
                <div class="procenter" v-if="item.name == '产品中心'">
                  {{item.name}}
                  <a class="showproa" :href="'#' + item1.path+'?id='+item1.id" v-for="(item1,index1) in item.children" :key="index1">{{item1.name}}</a>
                </div>
                <a v-else :href="'#' + item.path+'?id='+item.id">{{item.name}}</a>
              </li>
            </ul>
          </div>
          <div class="col-4 col-lg-2" v-if="navlist.length">
            <ul class="list-unstyled text-small foot-list">
              <li>
                <a href="javascript:void(0)">{{navlist[2].name}}</a>
              </li>
              <li v-for="item in navlist[2].children " :key="item.id">
                <a :href="'#' + item.path+'?id='+item.id">{{item.name}}</a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-1" v-if="navlist.length">
            <ul class="list-unstyled text-small foot-list">
              <li>
                <a :href="'#' + navlist[3].path">{{navlist[3].name}}</a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-1">
            <ul class="list-unstyled text-small foot-list">
              <li>
                <a href="#/about">联系我们</a>
              </li>
            </ul>
          </div>
          <div class="col-4 col-lg-3">
            <ul v-if="companyItem.switch" class="list-unstyled text-small foot-list contact">
              <li>
                <a href="javascript:void(0)">{{companyItem.switch}}</a>
              </li>
              <li>
                <a href="javascript:void(0)">{{companyItem.email}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-bot">
      <div v-if="companyItem.ICP" class="foot-bottom d-flex justify-content-end align-items-center">
        <div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{companyItem.ICP}}</a>
        </div>
        <div class="foot-two">
          <img src="../assets/images/index12.png" alt="">
          <div>{{companyItem.international}}</div>
        </div>
        <div>中国</div>
      </div>
    </div>
    <!-- 底部区域 -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      navlist: [], // 导航信息
      companyItem: {}// 公司信息
    }
  },
  created () {
    // 先从vuex 获取数据
    if (this.$store.state.home.navItem.length) {
      this.navlist = this.$store.state.home.navItem
      this.companyItem = this.$store.state.home.companyItem
    } else {
      this.getnav()
      this.getcompany()
    }
  },
  methods: {
    // 获取导航栏信息
    async getnav () {
      await this.$store.dispatch('home/getnav')
      this.navlist = this.$store.state.home.navItem
      // console.log(this.navlist)
    },
    // 获取公司信息
    async getcompany () {
      await this.$store.dispatch('home/getcompany')
      this.companyItem = this.$store.state.home.companyItem
      // console.log(this.companyItem)
    }
  }
}
</script>

<style lang="less" scoped>
//底部区域
.foot-top{
  padding-top: 4rem;
  padding-bottom: 2.5rem;
  font-size: 1rem;
  color: #d2d2d2;
  a{
    color: #d2d2d2;
    text-decoration: none;
  }
  .foot-list{
    padding-top: 1.5rem;
    margin-bottom: 0;
      li{
        margin-bottom: 16px;
    }
  }
  .contact{
      padding: 10px 10px;
      border: 1px solid #fff;
      border-radius: 5px;
      margin-top: 20px;
      font-size: 16px;
      width: 275px;
  }
}
.foot-bot{
  background-color: #646464;
  .foot-bottom{
    height: 72px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 72px;
    color: #d2d2d2;
    font-size: 16px;
    a{
      color: #d2d2d2;
      text-decoration: none;
    }
    .foot-two{
      display: flex;
      align-items: center;
      padding: 0 20px;
      img{
        margin-right: 12px;
      }
    }
  }
}
.procenter{
  cursor: pointer;
  &:hover{
    a{
      display: block;
    }
  }
  a{
      display: none;
      margin: 10px 20px;
      margin: 10px 20px;
  }
}
@media screen and (max-width:1080px) {
    .showproa{
      display: block !important;
    }
}
</style>
