import { GetCase } from '@/api/case'
export default {
  namespaced: true,
  state: {
    caseItem: {} // 成功案例的信息
  },
  mutations: {
    setcaseItem (state, item) {
      state.caseItem = item
    }
  },
  actions: {
    // 获取成功案例的信息
    async  getcase ({ commit }) {
      const res = await GetCase()
      commit('setcaseItem', res.results[0])
    }
  },
  getters: {}
}
