import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 配置一级路由
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/energy',
    component: () => import('@/views/solution/energy-sol')
  },
  {
    path: '/intel',
    component: () => import('@/views/solution/intel-sol')
  },
  {
    path: '/wisdom',
    component: () => import('@/views/solution/wisdom-sol')
  },
  {
    path: '/servecenter',
    component: () => import('@/views/serve/center-serve')
  },
  {
    path: '/downcenter',
    component: () => import('@/views/serve/down-serve')
  },
  {
    path: '/case',
    component: () => import('@/views/case/succ-case')
  },
  {
    path: '/about',
    component: () => import('@/views/about/about-us')
  },
  {
    path: '/aboutdetail',
    component: () => import('@/views/about/about-detail')
  },
  {
    path: '/prodetail',
    component: () => import('@/views/product/pro-detail')
  },
  {
    path: '/nrsdetail',
    component: () => import('@/views/product/nrs-detail')
  }

]
const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   // 如果之前有保存滚动位置，则恢复到之前保存的位置
    //   return savedPosition
    // } else {
    // 否则，默认滚动到页面顶部
    return { x: 0, y: 0 }
    // }
  }
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
