import { GetNav, GetIndex, GetCompany } from '@/api/home'
export default {
  namespaced: true,
  state: {
    navItem: [], // 导航信息
    indexItem: [], // 首页的数据
    companyItem: {} // 公司信息
  },
  mutations: {
    setnavItem (state, item) {
      state.navItem = item
    },
    setindexItem (state, item) {
      state.indexItem = item
    },
    setcompanyItem (state, item) {
      state.companyItem = item
    }
  },
  actions: {
    // 获取顶部导航栏
    async getnav ({ commit }) {
      const res = await GetNav()
      commit('setnavItem', res.results)
    },
    // 获取首页的数据
    async getindex ({ commit }) {
      const res = await GetIndex()
      commit('setindexItem', res.results)
    },
    // 获取公司的信息
    async getcompany ({ commit }) {
      const res = await GetCompany()
      commit('setcompanyItem', res.results[0])
    }
  },
  getters: {}
}
